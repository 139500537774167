<app-homefour-banner></app-homefour-banner>

<div class="partner-area bg-f5f5f5 ptb-100">
    <div class="container">
        <div class="section-title" *ngFor="let Content of partnerSectionTitle;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>
        <app-partner></app-partner>
    </div>
</div>

<app-homefour-features></app-homefour-features>

<app-main-services></app-main-services>

<app-services></app-services>

<app-testimonials></app-testimonials>

<app-funfacts></app-funfacts>

<app-team-style-two></app-team-style-two>

<app-homefour-featured-services></app-homefour-featured-services>

<app-homefour-blog></app-homefour-blog>

<app-subscribe></app-subscribe>