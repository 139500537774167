<!-- Start Features Area -->
<div class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Content of featuresBox;">
                <div class="features-box">
                    <div class="icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{Content.link}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.link}}" class="link-btn">{{Content.linkText}}<i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Features Area -->