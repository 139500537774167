<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Creative Services </h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->





<div class="overview-area services-details-overview ptb-100">
    <div class="container">
        <div class="overview-box row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/design.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <h2>Design</h2>
                    <p>At Surakshate, great design is more than just how things look and feel. Everything boils down to how it adds value and makes things operate. It is a solution to a problem, a bridge over a chasm in communication. It's referred to as empathy. It is the boldness to stand out, to be loud and clear, and to proclaim to the world an idea that is so real that it stands out for its sheer audacity. And we're here to help you.</p>
                    	
				<ul style="margin-top:20px;">
				<li>Branding</li>
				<li>Brochures</li>
				<li>Flyers</li>
				<li>Standees</li>
				<li>Presentations</li>
				<li>Internal Communication</li>
				<li>Marketing Collaterals</li>
				</ul>
                </div>
            </div>
        </div>
        <div class="overview-box row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/video.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <h2>Video Services</h2>
                    <p>Surakshate is capable of conceptualizing, producing, and disseminating various types of video content. Our ability to compose captivating brand tales in a range of video formats is the foundation of our visual storytelling capability. We are persuaded that a mesmerizing blend of sound, lights, animation, and motion can bring any concept to life.</p>
                    <ul style="margin-top:20px;">
					<li>Product Demos / Explainer</li>
					<li>Engineering Videos</li>
					<li>Infographics Videos</li>
					<li>Walkthrough videos</li>
					<li>Whiteboard animation</li>
					<li>Kinetic text animation</li>
					</ul>
                </div>
            </div>
        </div>
        <div class="overview-box row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/presentation.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <h2>Presentations</h2>
                    <p>A presentation is an effective tool for engaging audiences, and the importance of a well-designed deck cannot be emphasized. With intelligent design, brief presentations, clear text, engaging infographics, and more in a mixed-media strategy that is geared to press all the right buttons, we ensure that anything you need to display and convey is front and centre.</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>









<app-lets-talk></app-lets-talk>


<!-- End Services Details Area -->