<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Cart</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<div class="cart-area ptb-70">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="cart-table-desc table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/products-details">
                                            <img src="assets/img/shop/products-img1.jpg" alt="item">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        <a routerLink="/products-details">Wireless Router</a>
                                    </td>
                                    <td class="product-price">
                                        <span class="unit-amount">$250.00</span>
                                    </td>
                                    <td class="product-quantity">
                                        <div class="input-counter">
                                            <span class="minus-btn"><i class="bx bx-minus"></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class="bx bx-plus"></i></span>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">$250.00</span>
                                        <a routerLink="/cart" class="remove"><i class='bx bx-x'></i></a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/products-details">
                                            <img src="assets/img/shop/products-img2.jpg" alt="item">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        <a routerLink="/products-details">Spy Secure</a>
                                    </td>
                                    <td class="product-price">
                                        <span class="unit-amount">$200.00</span>
                                    </td>
                                    <td class="product-quantity">
                                        <div class="input-counter">
                                            <span class="minus-btn"><i class="bx bx-minus"></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class="bx bx-plus"></i></span>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">$200.00</span>
                                        <a routerLink="/cart" class="remove"><i class='bx bx-x'></i></a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/products-details">
                                            <img src="assets/img/shop/products-img3.jpg" alt="item">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        <a routerLink="/products-details">White CCTV</a>
                                    </td>
                                    <td class="product-price">
                                        <span class="unit-amount">$200.00</span>
                                    </td>
                                    <td class="product-quantity">
                                        <div class="input-counter">
                                            <span class="minus-btn"><i class="bx bx-minus"></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class="bx bx-plus"></i></span>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">$200.00</span>
                                        <a routerLink="/cart" class="remove"><i class='bx bx-x'></i></a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="product-thumbnail">
                                        <a routerLink="/products-details">
                                            <img src="assets/img/shop/products-img4.jpg" alt="item">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        <a routerLink="/products-details">Dynamic Headphone</a>
                                    </td>
                                    <td class="product-price">
                                        <span class="unit-amount">$150.00</span>
                                    </td>
                                    <td class="product-quantity">
                                        <div class="input-counter">
                                            <span class="minus-btn"><i class="bx bx-minus"></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class="bx bx-plus"></i></span>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">$150.00</span>
                                        <a routerLink="/cart" class="remove"><i class='bx bx-x'></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="cart-buttons-desc">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-sm-8 col-md-8">
                                <div class="shopping-coupon-code">
                                    <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                                    <button type="submit">APPLY COUPON</button>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-4 col-md-4 text-right">
                                <a routerLink="/cart" class="default-btn"><i class='bx bx-refresh'></i> UPDATE CART</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="cart-totals-desc">
                        <h3>Cart Totals</h3>
                        <ul>
                            <li>Subtotal <span>$750.00</span></li>
                            <li>Total <span>$750.00</span></li>
                            <li>Payable Total <span>$750.00</span></li>
                        </ul>
                        <a routerLink="/checkout" class="default-btn">PROCEED TO CHECKOUT</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Cart Area -->

<app-lets-talk></app-lets-talk>