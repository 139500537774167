<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Simple Flexible Pricing</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<div class="pricing-area ptb-100">
    <div class="container">
        <div class="table-responsive pricing-table">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <h3>Standard</h3>
                            <p>Starting at</p>
                            <span><sup>$</sup>39.99</span>
                            <a href="#" class="default-btn">GET A DEMO</a>
                        </th>
                        <th>
                            <h3>Premium</h3>
                            <p>Starting at</p>
                            <span><sup>$</sup>69.99</span>
                            <a href="#" class="default-btn">GET A DEMO</a>
                        </th>
                        <th>
                            <h3>Platinum</h3>
                            <p>Starting at</p>
                            <span><sup>$</sup>99.99</span>
                            <a href="#" class="default-btn">GET A DEMO</a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Detect 500+ web vulnerabilities</td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>HTML5 websites & AJAX-heavy client-side</td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Acunetix AcuSensor</td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-x-circle'></i></td>
                    </tr>
                    <tr>
                        <td>Out-of-band Vulnerability Testing</td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Continuous Scanning</td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Role-Based Access Controls</td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Compliance Reports</td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Multiple Scan Engines</td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Web Application Discovery</td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                    <tr>
                        <td>Customizable Workflows</td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-x-circle'></i></td>
                        <td><i class='bx bxs-badge-check'></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- End Pricing Area -->

<app-lets-talk></app-lets-talk>