<!-- Start Services Area -->
<div class="{{mainServicesClass}}">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="main-services-slides">
            <owl-carousel-o [options]="mainServicesSlides">
                <ng-template carouselSlide *ngFor="let Content of singleMainServices;">
                    <div class="single-main-services">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12 content">
                                <div class="inner-content">
                                    <h3>{{Content.title}}</h3>
                                    <p *ngFor="let Desc of Content.paragraphList">{{Desc.paragraph}}</p>
                                    <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class='bx bx-right-arrow-alt'></i>{{Content.buttonText}}</a>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-12 image">
                                <img [src]="Content.img" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- End Services Area -->