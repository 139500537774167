<!-- Start Related Products Area -->
<div class="products-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Related Products</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleProductsBox">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/{{Content.detailsLink}}">
                            <img [src]="Content.img" class="main-image" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <div class="price">
                            <span class="new-price">{{Content.newPrice}}</span>
                            <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart">Add to Cart</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Related Products Area -->