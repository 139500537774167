<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Checkout</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Checkout Area -->
<div class="checkout-area ptb-70">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="billing-details-desc">
                        <h3>Billing Details</h3>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <div class="select-box">
                                        <select>
                                            <option>United Kingdom</option>
                                            <option>China</option>
                                            <option>Germany</option>
                                            <option>Japan</option>
                                            <option>United Arab Emirates</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <div class="select-box">
                                        <select>
                                            <option>Tokyo</option>
                                            <option>Shanghai</option>
                                            <option>São Paulo</option>
                                            <option>Beijing</option>
                                            <option>New York</option>
                                            <option>Istanbul</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Order Notes</label>
                                    <textarea name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12">
                    <div class="order-details-desc">
                        <h3>Your Order</h3>

                        <ul class="order-details">
                            <li>Subtotal <span>$268.00</span></li>
                            <li>Discount <span>$0.00</span></li>
                            <li>Shipping <span>$20.00</span></li>
                            <li>Tax <span>$00.00</span></li>
                            <li>Order Total <span>$288.00</span></li>
                        </ul>

                        <div class="payment-method">
                            <p>
                                <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                Make your payment directly into our bank account. Please use your Order ID as the payment reference.
                            </p>
                        </div>

                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="example-checkbox">
                            <label class="form-check-label" for="example-checkbox">I've read and accept the <a routerLink="/terms-conditions">Terms & Condtions</a>*</label>
                        </div>

                        <button type="submit" class="default-btn">PLACE ORDER</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Checkout Area -->

<app-lets-talk></app-lets-talk>