<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Awards & Recognition</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Awards Area -->
<div class="awards-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">OVERVIEW</span>
            <h2>Our Awards</h2>
            <p>Our cyber security awards and industry recognition</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-awards-box">
                    <img src="assets/img/awards/awards-img1.jpg" alt="image">
                    <h3>Chief Technology Officer of the Year</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-awards-box">
                    <img src="assets/img/awards/awards-img2.jpg" alt="image">
                    <h3>Cybersecurity Blogger of the Year</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-awards-box">
                    <img src="assets/img/awards/awards-img3.jpg" alt="image">
                    <h3>Cybersecurity Consultant of the Year</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-awards-box">
                    <img src="assets/img/awards/awards-img4.jpg" alt="image">
                    <h3>Cybersecurity Educator of the Year</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Awards Area -->

<!-- Start Partner Area -->
<div class="partner-area bg-f5f5f5 ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Trusted by Top Brands</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <app-partner></app-partner>
    </div>
</div>
<!-- End Partner Area -->

<app-lets-talk></app-lets-talk>