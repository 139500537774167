<!-- Start Main Banner Area -->
<div class="banner-wrapper-area" *ngFor="let Content of bannerContent;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-content">
                    <h1>{{Content.title}}</h1>
                    <p *ngFor="let List of Content.paragraphList">{{List.paragraph}}</p>
                    <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}<i class='bx bxs-chevron-right'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-image">
                    <img [src]="Content.img" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->