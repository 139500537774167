<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let Content of singleTeamBox;">
                <div class="single-team-box">
                    <div class="image">
                        <img [src]="Content.img" alt="image">
                        <span class="share-btn"><i class='bx bx-share-alt'></i></span>
                        <ul class="social">
                            <li *ngFor="let SocialItem of Content.social"><a href="{{SocialItem.link}}" class="{{SocialItem.extraClass}}" target="_blank"><i class='{{SocialItem.icon}}'></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{Content.name}}</h3>
                        <span>{{Content.designation}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Team Area -->

<app-lets-talk></app-lets-talk>