<!-- Start Footer Area -->
<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="assets/img/surakshate-16.png" alt="image">
                    </a>
                    <p>Founded in 2021 DUBAI, UAE. SURAKSHATE is a team of like-minded IT Security Experts  who bring immense industry experience and a new innovative approach in representing Global, National and Enterprise level technology across the MEA and India Region. </p>
                    <ul class="social-links">
                        
                        <li><a href="https://www.linkedin.com/company/surakshate/?viewAsMember=true" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                       
                    </ul>
                </div>
            </div>

       <!--      <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>Quick Links</h3>

                    <ul class="links-list">
					    <li><a routerLink="/about">About Us</a></li>
					    <li><a routerLink="/solutions-details">Solutions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 style="margin-top: 13px;">Services List</h3>

                    <ul class="links-list">
					    <li><a routerLink="Pentesting-and-Bugbounty-Platforms">Bug bounty Platform and Pen testing services</a></li>
					    <li><a routerLink="Managed-detection-and-response">Managed detection and response (MDR)</a></li>
					    <li><a routerLink="Improve-Security-Risk-and-Compliance">Improve Security Risk and Compliance </a></li>
                        <li><a routerLink="cyber-security-training-services">Cyber Security Training Services</a></li>
                        <li><a routerLink="managed-security-services">Managed Security Services & Security Assessment</a></li>
                        <li><a routerLink="infrastructure-management">IT Iinfrastructure Management & Support Services</a></li>
                        <li><a routerLink="compliance-management">Governance Risk and Compliance</a></li>
                        <li><a routerLink="data-center-and-cloud-advisory-services">Data Center, Cloud Advisory and Professional Services </a></li>
                        <li><a routerLink="creative-services">Creative Services </a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 style="margin-top: 13px;">Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><strong> Address:</strong> <br/>SURAKSHATE FZCO<br/>
A2, 101,DUBAI DIGITAL PARK<br/>
DUBAI SILICON OASIS<br/>
PO BOX 342001 <br/> UNITED ARAB EMIRATES
</li>
                        <li><strong>Email : </strong> <a href="mailto:hello@surakshate.com"> hello@surakshate.com</a></li>
                        <li><strong>Mobile :</strong> <a href="tel:00971 564800053"> +971 56480 0053
</a></li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>Copyright @2023 <strong>Surakshate</strong> </p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->