import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-improve',
  templateUrl: './improve.component.html',
  styleUrls: ['./improve.component.scss']
})
export class ImproveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
