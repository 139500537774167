<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="naxai-responsive-nav">
        <div class="container">
            <div class="naxai-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/surakshate-16.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="naxai-nav">
        <div class="container-fluid {{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/surakshate-16.png" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                      <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                         <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
						 
                        <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <div class="row">
                                        <div class="col">
                                           

                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Email Compromise</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Risk Mgmt Framework</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Compliance</a></li>
                                                
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Third Party Risk Mgmt</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Application Security Solutions</a></li>
                                                
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Threat Intellegence</a></li>
												
												<li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Crowd Sourced Pen testing and Bug bounty</a></li>
												   
												<li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MDR,Response Automation</a></li>
												
												<li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Endpoint Mgmt and Protection </a></li>
												
                                            </ul>
                                        </div>

                                        <div class="col">
                                     

                                            <ul class="megamenu-submenu">
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ICS Security</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">OT and IOT Security</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Network Security</a></li>
                                                
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Digital Process Automation</a></li>

                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cyber Security </a></li>
                                                
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SOC  and MSSP,Cloud</a></li>
												
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Identity Mgmt and Access Control</a></li>
												
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Zero Trust Network Access & VPN Solutions </a></li>
												
                                                <li><a routerLink="/services-details" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Governance Risk and Compliance</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul> -->
                        </li>

                        <li class="nav-item megamenu"><a routerLink="/solutions" class="dropdown-toggle nav-link">Services</a>
                           <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <div class="row">
                                        <div class="col">
                                           

                                            <ul class="megamenu-submenu">
											 <li><a routerLink="/Pentesting-and-Bugbounty-Platforms" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bug bounty Platform and Pen testing services</a></li>
											 
											  <li><a routerLink="/Managed-detection-and-response" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Managed detection and response (MDR)  </a></li>
											  
											   <li><a routerLink="/Improve-Security-Risk-and-Compliance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Improve Security Risk and Compliance  </a></li>
											   
											   
                                                <li><a routerLink="/cyber-security-training-services" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cyber Security Training Services</a></li>

                                                <li><a routerLink="/managed-security-services" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Managed Security Services & Security Assessment</a></li>

                                                <li><a routerLink="/infrastructure-management" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IT Iinfrastructure Management  & Support Services</a></li>
                                                
                                                <li><a routerLink="/compliance-management" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Governance Risk and Compliance</a></li>

                                                <li><a routerLink="/data-center-and-cloud-advisory-services" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Data Center, Cloud Advisory and Professional Services </a></li>
												
                                                <li><a routerLink="/creative-services" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Creative Services  </a></li>
                                            </ul>

                                       
                                        </div>

                                        
                                    </div>
                                </li>
                            </ul> 
                        </li>

                        

                        

                        
          
						
						 <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                    <div class="others-option d-flex align-items-center">
                      <ul class="social-links">
                        
                        <li class="icsan"><a  href="https://www.linkedin.com/company/surakshate/?viewAsMember=true" target="_blank"><i class="sanbx bx bxl-linkedin"></i></a></li>
          
                    </ul>
                       
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class='bx bx-right-arrow-alt'></i> GET IN TOUCH</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search here...">
                                <button type="submit"><i class='bx bx-search-alt'></i></button>
                            </form>
                        </div>

                        <div class="d-flex align-items-center justify-content-center">
                            <div class="option-item">
                                <a routerLink="cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                            </div>

                            <div class="option-item">
                                <a   routerLink="/contact" class="default-btn"><i class='bx bx-right-arrow-alt'></i> GET IN TOUCH</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->