<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Customer Success Manager - SMB</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Careers Area -->
<div class="careers-area pt-100 pb-70">
    <div class="container">
        <div class="careers-details-content">
            <p>We are accepting applications for areas such as Frontend Development/Backend Development/WordPress Theme &amp; Plugin Development/UX-UI Design/Digital Marketing/Content Writing, etc.</p>
            <p>Interested candidate should be detail-oriented, a team player, and can learn and work independently on multiple assigned projects. If you think you are a hard-worker and can learn + work under pressure then we recommend you to apply based on your skills!</p>
            <h3>Requirements</h3>
            <ul>
                <li>Actively pursuing a college degree in Computer Science or related field, or any equivalent combination of education, training, and experience that demonstrates the required skills.</li>
                <li>Ability to communicate performance delays or barriers immediately to allow others to adjust timetables and plans. Maintain good attendance and be on time.</li>
                <li>Ability to multitask in a fast-paced environment and strive for excellence in service to all customers.</li>
                <li>Course completed from <strong>Naxai Training Center</strong> will be given preference.***</li>
            </ul>
            <p>Provide us with a good cover letter, 1 or 2 paragraphs giving us key points as to why you feel this position is a strong fit for you. Before applying, make sure you can work full time.</p>
            <h3>Job Type</h3>
            <p>– Working method: On-site / Remote<br> – Working hour: 10 AM – 6 PM<br> – Workdays: 6 days/week (Saturday-Thursday) [ We will go for 2-3 weekend days soon!]</p>
            <h3>Job Location</h3>
            <p>– 2750 Quadra Street Victoria, Canada<br> – Remote ( Anywhere from CANADA)</p>
            <p>This is a very collaborative and inclusive work environment where individuals strong on aptitude and attitude will have an opportunity to grow their professional careers by working with creative, technical, and business leaders.</p>
            <h3>How to Apply?</h3>
            <p>Send your updated CV with a cover letter mentioning why you are perfect for this position! The CV MUST be in <strong>PDF format</strong>. Include probable nearest joining date if we select you. Also, include your portfolio or websites that you have already worked on. Share your portfolio link so we can review it.</p>
            <p>Please don’t apply if you can’t give enough effort after joining! If you are involved with full-time study or any other part-time job then check your availability before applying.</p>
            <p>Send your application to <span><a href="mailto:hello@naxai.com"><strong>hello@naxai.com</strong></a></span> with the email title “YourSkillTrack_Your Name_Subject_University”.</p>
            <p><strong>Only APPLY if you can work hard and achieve goals! If you can show good performance then we can offer you a permanent position instantly.</strong></p>
        </div>
    </div>
</div>
<!-- End Careers Area -->

<app-subscribe></app-subscribe>