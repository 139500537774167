<!-- Start Services Area -->
<div class="services-area pt-100 bg-d8eafe">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <span class="sub-title">{{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleServicesItem;">
                <div class="single-services-item">
                    <div class="icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.btnText}}<i class='bx bx-chevron-right'></i></a>
                    <div class="back-icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
</div>
<!-- End Services Area -->