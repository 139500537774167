<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Case Studies Area -->
<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleCaseStudiesBox | paginate: { itemsPerPage: 6, currentPage: caseStudiesGrid }">
                <div class="single-case-studies-box">
                    <img [src]="Content.img" alt="image">
                    <div class="content">
                        <span><i class='bx bx-calendar-star'></i> {{Content.date}}</span>
                        <h3>{{Content.title}}</h3>
                        <a routerLink="/{{Content.link}}" class="read-more-btn">{{Content.linkText}}<i class='bx bx-chevron-right'></i></a>
                    </div>
                    <a routerLink="/{{Content.link}}" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="caseStudiesGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Case Studies Area -->

<app-lets-talk></app-lets-talk>