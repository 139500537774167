import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent = [
        {
            bgImage: `assets/img/main-banner1.jpg`,
            title: `We Protect the Most Sensitive Data`,
            paragraph: `Surakshate, the region's Incubation based Premier Technology solutions provider, helps Launch New Solutions  and help Organizations address the Current Challenges with a unique tailored approach.`,
            buttonBox: [
                {
                    buttonText: `Register Now`,
                    buttonIcon: `bx bxs-chevron-right`,
                    buttonLink: `contact`
                }
               
            ]
        }
    ]

}