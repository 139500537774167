<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost | paginate: { itemsPerPage: 6, currentPage: blogGrid }">
                <div class="single-blog-post without-bg-color">
                    <div class="post-image">
                        <a routerLink="/{{Content.detailsLink}}">
                            <img [src]="Content.mainImage" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="meta">
                            <li><i class='bx bx-purchase-tag'></i> {{Content.tag}}</li>
                            <li><i class='bx bx-user-circle'></i> {{Content.authorName}}</li>
                        </ul>
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <p>{{Content.paragraph}}</p>
                        <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.linkText}}<i class='bx bx-chevron-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<app-lets-talk></app-lets-talk>