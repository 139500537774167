<!-- Start Main Banner Area -->
<div class="main-banner-area" style="background-image: url({{Content.bgImage}});" *ngFor="let Content of mainBannerContent;">
    <div class="container-fluid">
        <div class="main-banner-content">
            <h1>{{Content.title}}</h1>
            <p>{{Content.paragraph}}</p>
            <div class="btn-box">
                <a href="https://www.surakshate.com/GISEC2024/#register_now" class="default-btn" *ngFor="let Button of Content.buttonBox">{{Button.buttonText}}<i class='{{Button.buttonIcon}}'></i></a>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->