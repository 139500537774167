<!-- Start Testimonials Area -->
<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <span class="sub-title">{{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="testimonials-slides-two">
            <owl-carousel-o [options]="testimonialsSlides">
                <ng-template carouselSlide *ngFor="let Content of singleTestimonialsBox;">
                    <div class="single-testimonials-box">
                        <div class="desc">
                            <p>{{Content.feedbackText}}</p>
                        </div>
                        <div class="client-info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img [src]="Content.userImg" alt="image">
                                <div class="title">
                                    <h3>{{Content.userName}}</h3>
                                    <span>{{Content.userDesignation}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- End Testimonials Area -->