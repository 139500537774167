<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Careers Area -->
<div class="careers-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6" *ngFor="let Content of singleJobsItem;">
                <div class="single-jobs-item">
                    <h3><a routerLink="/{{Content.link}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.link}}" class="link-btn">{{Content.linkText}}<i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Careers Area -->

<app-subscribe></app-subscribe>