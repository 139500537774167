import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor( private titleService: Title ) {
        this.titleService.setTitle('Surakshate - About Us');
    }

    ngOnInit(): void {
    }

}