import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            subTitle: `OUR SERVICES AREA`,
            title: `We Offer Professional Security Solutions For Business`,
            paragraph: ``
        }
    ]
    singleServicesBox = [
        {
            icon: `flaticon-bank`,
            title: `Cyber Security Training Services`,
            paragraph: `Cybersecurity training encourages employees to understand IT security issues, identify security risks, and learn`,
            detailsLink: `cyber-security-training-services`,
            btnText: `READ MORE`
        },
        {
            icon: `flaticon-manufacturing`,
            title: `Managed Security Services`,
            paragraph: `Managed security services (MSS) are network security services that have been outsourced to a service provider`,
            detailsLink: `managed-security-services`,
            btnText: `READ MORE`
        },
        {
            icon: `flaticon-oil-1`,
            title: `IT Infrastructure Management`,
            paragraph: `An IT infrastructure is necessary for the operation and management of a company’s IT services, both internal`,
            detailsLink: `infrastructure-management`,
            btnText: `READ MORE`
        },
        {
            icon: `flaticon-antivirus`,
            title: `Compliance Management and Risk Assurance`,
            paragraph: `In a volatile and dynamically changing business environment, risk management, and information assurance play`,
            detailsLink: `compliance-management`,
            btnText: `READ MORE`
        },
        {
            icon: `flaticon-keywords`,
            title: `Data Center and Cloud Advisory Services`,
            paragraph: `A data center's design is based on a network of computing and storage resources that enable the delivery of `,
            detailsLink: `data-center-and-cloud-advisory-services`,
            btnText: `READ MORE`
        },
        {
            icon: `flaticon-protection`,
            title: `Creative Services`,
            paragraph: `At Surakshate, great design is more than just how things look and feel. Everything boils down to how it adds value`,
            detailsLink: `creative-services`,
            btnText: `READ MORE`
        }
    ]

    servicesSlides: OwlOptions = {
        loop: true,
		nav: false,
		dots: true,
		margin: 30,
        autoplay: true,
        autoplayTimeout: 4000,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			}
		}
    }

}