<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Managed detection & response(MDR)</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->





<div class="overview-area services-details-overview ptb-100">
    <div class="container">
        <div class="overview-box row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/services-img3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <h2>Managed detection & response(MDR)</h2>
                    <p>Managed detection and response (MDR) providers deliver round the clock threat monitoring, detection and  response services to customers leveraging a combination of technologies deployed at the host and network layers, advanced analytics, threat intelligence, and human expertise in incident investigation and response. MDR providers undertake incident validation, and can offer remote response services, such as threat containment, and support in bringing a customer's environment back to some form of "known good."</p>




                </div>
            </div>
			
		
        </div>
        
    </div>
</div>










<app-lets-talk></app-lets-talk>


<!-- End Services Details Area -->