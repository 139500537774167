<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleServicesBox;">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.btnText}}<i class='bx bx-chevron-right'></i></a>
                    <div class="back-icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<app-lets-talk></app-lets-talk>