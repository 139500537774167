<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Contact Us</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Address</h3>
                            <p><a href="#" target="_blank"> <strong>SURAKSHATE FZCO<br/>
A2, 101, DUBAI DIGITAL PARK<br/>
 DUBAI SILICON OASIS<br/>
 PO BOX 342001<br/> UNITED ARAB EMIRATES</strong>
</a></p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Phone</h3>
                            <p><a href="tel:00971 564800053">UAE : +971 56480 0053</a></p>
							<p><a href="tel:00971 564800053">India : +91 9739340330</a></p>
							
                            
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-envelope-open'></i>
                            </div>
                            <h3>Email</h3>
                            <p><a href="mailto:hello@naxai.com">hello@surakshate.com</a></p>
                            <p><a href="mailto:lakshman@surakshate.com">lakshman@surakshate.com</a></p>
                            
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-3">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Working Hours</h3>
                            <p>Sunday - Friday</p>
                            <p>9:00AM - 6:00PM</p>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>

<div id="maps" class="">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4295.994639856223!2d55.374066887979566!3d25.119238366690503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f651c2438dc53%3A0xe6abcc1ea38a17f5!2sD1%20-%20Dubai%20Digital%20Park!5e0!3m2!1sen!2sin!4v1639054991462!5m2!1sen!2sin"></iframe>
</div>
<!-- End Contact Area -->
