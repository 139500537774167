<!-- Start Why Choose Us Area -->
<div class="why-choose-area bg-d8eafe">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-img" *ngFor="let Image of whyChooseUsImage;" style="background-image: url({{Image.img}});">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-text">
                    <div class="content" *ngFor="let Content of whyChooseUsContent;">
                        <span class="sub-title">{{Content.subTitle}}</span>
                        <h2>{{Content.title}}</h2>
                        <ul class="features-list">
                            <li *ngFor="let List of Content.featuresList">
                                <div class="icon">
                                    <i class="{{List.icon}}"></i>
                                </div>
                                <h3>{{List.title}}</h3>
                                <p>{{List.paragraph}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Why Choose Us Area -->