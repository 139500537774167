import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    whyChooseUsImage = [
        {
            img: `assets/img/why-choose-us.jpg`
        }
    ]
    whyChooseUsContent = [
        {
            subTitle: ``,
            title: `WHY CHOOSE SURAKSHATE`,
            featuresList: [
                {
                    icon: `flaticon-computer`,
                    title: `Incubation Based Approach`,
                    paragraph: `We Help understand your current  challenges and  engage to build a strategic approach`
                },
                {
                    icon: `flaticon-call-center-agent`,
                    title: `Collaborative Engagement `,
                    paragraph: `We create unique proposition for End Users , MSSPs and OEM Vendors with our collaborative approach .`
                },
                {
                    icon: `flaticon-development`,
                    title: `Professional Consulting`,
                    paragraph: `With  Our  highly skilled and experienced professional teams on technology and Services we help customers meet their business delivery targets on time and on schedule.`
                },
				
				   {
                    icon: `flaticon-puzzle-pieces`,
                    title: `Innovative and Creative Ideas`,
                    paragraph: `We Create innovative Ideas to address the Current and future challenges . Ex. Ransomware ATTACK Protection solutions etc.	`
                },
				
				
				   {
                    icon: `flaticon-money-1`,
                    title: `Budget-Friendly Analysis`,
                    paragraph: `Guide End Users decide on the right Vendor technology practices with Consultative Approach towards addressing the needs.`
                },
				
				   {
                    icon: `flaticon-fast-time`,
                    title: `Excellent timing`,
                    paragraph: `We Ensure the Projects are completed on time to not have  Revenue leakage on Budgets.`
                }
				
            ]
        }
    ]

}