<!-- Start Why Choose Us Area -->
<div class="why-choose-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image" *ngFor="let Image of whyChooseUsImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content" *ngFor="let Content of whyChooseUsContent;">
                    <span class="sub-title">{{Content.subTitle}}</span>
                    <h2>{{Content.title}}</h2>
                    <ul class="features-list">
                        <li *ngFor="let List of Content.featuresList">
                            <div class="icon">
                                <i class="{{List.icon}}"></i>
                            </div>
                            <h3>{{List.title}}</h3>
                            <p>{{List.paragraph}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Why Choose Us Area -->