<!-- Start Main Banner Area -->
<div class="banner-area" *ngFor="let Content of bannerContent;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <h1>{{Content.title}}</h1>
                    <p>{{Content.paragraph}}</p>
                    <div class="btn-box">
                        <a routerLink="/{{Button.buttonLink}}" class="default-btn" *ngFor="let Button of Content.buttonBox">{{Button.buttonText}}<i class='{{Button.buttonIcon}}'></i></a>
                    </div>
                    <a href="{{Content.videoLink}}" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image" style="background-image: url({{Content.img}});">
                    <img [src]="Content.img" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->