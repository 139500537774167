<app-hometwo-banner></app-hometwo-banner>

<div class="partner-area pb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-hometwo-features></app-hometwo-features>

<app-why-choose-us></app-why-choose-us>

<app-main-services></app-main-services>

<app-services></app-services>

<app-hometwo-testimonials></app-hometwo-testimonials>

<app-funfacts></app-funfacts>

<app-team-style-one></app-team-style-one>

<div class="partner-area bg-f5f5f5 ptb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<div class="services-area bg-f5f5f5">
    <app-featured-services></app-featured-services>
    <div class="divider"></div>
</div>

<app-blog></app-blog>

<app-lets-talk></app-lets-talk>