import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-features',
    templateUrl: './homeone-features.component.html',
    styleUrls: ['./homeone-features.component.scss']
})
export class HomeoneFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleFeaturesBox = [
     
        {
            icon: `flaticon-call-center-agent`,
            title: `Business Email Compromise`,
            paragraph: `Two weakest links are Humans and Email. Business Email Compromise (BEC) is a vulnerability of which an attacker gets access`,
            link: `solutions`,
            linkText: `READ MORE`
        },
		
		   {
            icon: `flaticon-video-conference`,
            title: `Application Security Solutions`,
            paragraph: `Application security is the process of developing, adding, and testing security features within applications to prevent`,
            link: `solutions`,
            linkText: `READ MORE`
        },
		
        {
            icon: `flaticon-cyber-security`,
            title: `Cyber Threat Intelligence -Dark Web , Data Breach Protection, Asset Discovery `,
            paragraph: `Dark web monitoring is the process of searching for and keeping track  `,
            link: `solutions`,
            linkText: `READ MORE`
        },
        {
            icon: `flaticon-hacker`,
            title: `Incubation Services for Global OEM Vendors`,
            paragraph: `Surakshate offers a choice of incubation methodologies for Global technology companies looking to penetrate `,
            link: `solutions`,
            linkText: `READ MORE`
        }
    ]

}