import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-main-services',
    templateUrl: './main-services.component.html',
    styleUrls: ['./main-services.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class MainServicesComponent implements OnInit {

    location: any;
    mainServicesClass: any;

    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/index-4'){
                    this.mainServicesClass = 'services-area pt-100 pb-70 bg-f9f9f9';
                } else {
                    this.mainServicesClass = 'services-area pt-100 pb-70';
                }
            }
        });
    }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: `Use Surakshate to Enhance Your Cyber Defense`,
            paragraph: ``
        }
    ]
    singleMainServices = [
        {
			title: `About Surakshate`,
			paragraphList: [
				{
					paragraph: `Founded in Dubai United Arab Emirates in 2021 SURAKSHATE is a team of like-minded IT security expert Organizations who together bring immense industry experience and a new, innovative approach to Global, National and Enterprise level technology across the MENA Region. `
				},
				{
					paragraph: `We are committed to developing out of the box solutions on Digital Transformation, Cyber Security, Information technology and offer various professional services that reflect the challenges organizations are facing in today’s ever-changing markets. Our creative methodology brings innovative approaches to old and new problems, creating the information technology platforms that every organization needs for this Century.`
				}
			],
            buttonLink: `about`,
            buttonText: `READ MORE`,
            img: `assets/img/services/services-img1.png`
		},
		
    ]

    mainServicesSlides: OwlOptions = {
		items: 1,
		nav: false,
		loop: false,
		margin: 30,
		dots: false,
		autoplay: false,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
        autoplayTimeout: 4000,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 1
			}
		}
    }

}